@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~bootstrap/scss/bootstrap";



@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

* {
  margin: 0;
  box-sizing: border-box;
  scroll-padding-top: 52px;
}

h2{
  margin: 0px;
}
@media (min-width: 768px) {
  * {
    scroll-padding-top: 64px;
  }
}


a {
  color: #ffffff;
  text-decoration: none;
}

body {
  padding-top: 0px;
  overflow-x: hidden;
}

/* VARIABLES */
:root {
  --primary-font: "Raleway", sans-serif;
  --secondary-font: "Work Sans", sans-serif;
  --bg-color: #e6f4f4;
  --primary-color: #0b8b8b;
  --secondary-color: #d70f0f;
  --text-main: #1e2029;
  --newtext-main: #8D0A0A;
  --white: #ffffff;
}

/* navbar */
animated-icon1,
.animated-icon2,
.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon1 span {
  background: rgb(221, 221, 221);
}

.animated-icon2 span {
  background: #e3f2fd00;
}

.animated-icon3 span {
  background: #f3e5f500;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}







.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler {
  outline: none !important;
}


a :hover {
  color: #fff;
}
.title {
  color: #d70f0f;
  font-weight: 600;
  font-size: 22px;
}
.navbar {
  background-color: transparent;
  position: sticky;
  top: 0;
}
.nav-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  text-align: center;
  color: #191616;
}

section {
  height: 100vh;

  width: 100%;
  margin: 0;
}

/* homepage 
.homebg{
  background: url("./assets/mobilebg.svg");
 


  background-size:cover;
  background-repeat: no-repeat;
}*/
.mainbg {
  width: 100%;
  height: 100vh;
  margin-bottom: 5px;
}

/*lobby */

.lobby-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.icons {
  height: 74px;
  width: 74px;
  object-fit: scale-down;
}

.maintext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* or 117% */
  text-align: center;
  letter-spacing: -0.5px;
  color: var(--white);
  margin-top: 1rem;
}

.box1 {
  position: absolute;
  width: 361px;
  height: 161px;
  left: 952px;
  top: 270px;
}

.overlay {
  width: 100%;
  height: 70vh;
  background: rgba(255, 255, 255, 0.005);
  box-shadow: 0px 1.1966px 29.915px rgba(69, 42, 124, 0.1);
  backdrop-filter: blur(2px);
}

.button1 {
  background-color: var(--secondary-color);
  left: 50%;
}

.about-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 54px;
  border-left: 5px solid var(--secondary-color);

  color: var(--text-main);
}

.about-text {
  font-family: "Poppins";


  font-weight: 400;

  color: #191616;
}

.about-text-box{
  font-size: 1em;
}

// .about-text-box {
//   position: absolute;
//   padding: 20px 20px;
//   display: block;
//   margin: auto;
// }

.button2 {
  font-weight: 400;
  background-color: var(--text-main);
}

@media screen and (max-width: 992px) {
  .mic_mob {
    display: none;
  }
}

/*PHASES*/



.container1 {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  backdrop-filter: blur(21px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  color: white;
  

}
.containermob {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  backdrop-filter: blur(21px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  color: white;
  margin: auto;
  padding-bottom:10px ;
  
  

}

/*phase */

.phasesbg {
  background-image: url('./assets/thebox2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: cover;
  z-index: 10;
}

@media screen and (max-width: 992px) {
  .phasesbg {
    padding-bottom: 2.5rem;
  }  
}

.phasesbg2 {
  background-image: url('./assets/thebox2.png');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}
.phase-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22x;

  color: #ffffff;
}

.phase-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 51px;
  text-align: center;

  color: #ffffff;
}

.border-radial {
  border-style: solid;
  border-width: 0.5px;
  border-image: radial-gradient(var(--secondary-color), #fff) 3;
}

/*contact */

.a-box {
  display: inline-block;
  width: 208px;
  text-align: center;
  height: 155px;
}

.img-container {
  height: 130px;
  width: 130px;
  

  display: inline-block;
  // outline: 1.5px solid;
  // outline-color: #d70f0f;
  padding: 5px;

  background: linear-gradient(152.97deg,
      rgba(255, 255, 255, 0.2) 4.27%,
      rgba(215, 215, 215, 0) 104.27%);
  backdrop-filter: blur(42px);
}

.img-container img {
  height: 120px;
}

.text-container {
  padding: 60px 20px 20px 20px;

  background: var(--text-main);
  font-family: "Poppins";
  font-weight: 400;
  margin: -70px 0px 0px 0px;
  line-height: 16px;
  font-size: 14px;
  color: #ffffff;
}

.text-container h3 {
  margin: 10px 0px 10px 0px;
  color: #ffffff;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
  text-align: center;
}



.reveal {
  position: relative;
  transform: translateX(150px);
  opacity: 0;
  transition: 2s all ease;
  transition-delay: 0.5s;
}

.reveal.active {
  transform: translateX(0);
  opacity: 1;
}

.reveal1 {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal1.active {
  transform: translateY(0);
  opacity: 1;
}


.animated-icon1,
.animated-icon2,
.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon1 span {
  background: rgb(221, 221, 221);
}

.animated-icon2 span {
  background: #e3f2fd00;
}

.animated-icon3 span {
  background: #f3e5f500;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}







.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler {
  outline: none;
}


a :hover {
  color: #fff;
}


.footer-bg {
  background-image: url('./assets/Wavefooterwave.png');
  background-repeat: no-repeat;

  background-position: bottom;
  background-size: 100%;
}




.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d),
(-webkit-transform-3d) {

  .carousel-fade .carousel-inner>.item.next,
  .carousel-fade .carousel-inner>.item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner>.item.prev,
  .carousel-fade .carousel-inner>.item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner>.item.next.left,
  .carousel-fade .carousel-inner>.item.prev.right,
  .carousel-fade .carousel-inner>.item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* just for demo purpose */
.carousel,
.carousel-inner,
.carousel-inner .item {
  height: 100%;
}

.item:nth-child(1) {
  background: blue;
}

.item:nth-child(2) {
  background: red;
}

.item:nth-child(3) {
  background: orange;
}

.phasesbg2 {
  background-image: url('./assets/mobilemg2.png');
  background-repeat: no-repeat;
  background-size: 100em auto ;
  background-size: cover;
  z-index: 10;
}


/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.175);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: rgba(255, 255, 255, 0.417);
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  
  fill: rgba(233, 233, 233, 0.855);
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
   margin-top: 5px;
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: rgb(217, 217, 217);
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.carousel-cell {
  width: 100%; /* full width */
  
 margin: auto;
 padding:0px 40px;
  /* center images in cells with flexbox */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.footer_heart {
  margin: 0 0.25rem;
  position: relative;
  top: 3px;
  
}

.Modal1{
  background-color:var(--primary-color);
  


}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}